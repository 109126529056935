import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import { apiClient } from './utils';
import './App.css';

const defaultFilename = 'No file chosen';

function App() {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [downloadable, setDownloadable] = useState<string[]>([]);
  const [filename, setFilename] = useState<string>(defaultFilename);

  const addOrders = (data: any) => {
    toast.loading('Adding orders...');
    apiClient
      .post('orders/add', data)
      .then(() => {
        toast.dismiss();
        toast.success('Orders added');
      })
      .catch((err) => {
        console.error(err);
        toast.dismiss();
        toast.error('Failed to add orders');
      });
  };

  const upload = (result: any) => {
    apiClient
      .post('orders/upload', result.data)
      .then((link) => {
        toast.dismiss();
        toast.success('File uploaded');
        setSelectedFile(null);
        setFilename(defaultFilename);
        setDownloadable([...downloadable, link]);
        addOrders(result.data);
      })
      .catch((err) => {
        console.error(err);
        toast.dismiss();
        toast.error('Failed to upload');
      });
  };

  const clickToUpload = () => {
    setDownloadable([]);
    if (!selectedFile) {
      return toast.error('Empty file');
    }
    if (!selectedFile.name.includes('.neworders')) {
      return toast.error('Wrong file format');
    }
    toast.loading('File uploading...');
    Papa.parse(selectedFile, { complete: upload, header: true });
  };

  return (
    <>
      <header className='w-screen bg-white absolute top-0 left-0'>
        <img
          className='w-28'
          src={`${process.env.REACT_APP_S3_BUCKET_LOGO_URL}/CALHOME_logo.png`}
          alt='logo'
        />
      </header>
      <div className='min-h-screen bg-primary flex flex-col items-center justify-center text-white'>
        <div className='text-4xl my-5'>Calhome file transform</div>
        <form className='flex flex-col justify-center items-center'>
          <div className='flex justify-center items-center my-6'>
            <input
              type='file'
              accept='.neworders'
              name='neworders'
              onChange={(e) => {
                if (e.target?.files) {
                  setFilename(e.target.files[0].name);
                  setSelectedFile(e.target.files[0]);
                }
              }}
              className='w-48 cursor-pointer'
            />
            <div>{filename}</div>
          </div>
          <input
            className='bg-green-500 text-white rounded py-2 w-20 cursor-pointer'
            type='button'
            value='Upload'
            onClick={clickToUpload}
          />
        </form>
        {downloadable.length > 0 && (
          <div className='mt-10 mb-2 text-xl'>Click below to download:</div>
        )}
        {downloadable.map((link, index) => (
          <div
            key={index}
            className='text-white cursor-pointer text-md underline hover:text-green-500'
            onClick={() => window.open(link, '_blank')}
          >
            Home Depot CSV
          </div>
        ))}
      </div>
    </>
  );
}

export default App;
